// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../libs/ID.res.js";
import * as $$Date from "../libs/Date.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

function fromJs(js) {
  return {
          id: js.id,
          countryId: js.countryId,
          stateId: js.stateId,
          name: js.name,
          slug: js.slug,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          altNames: js.altNames,
          description: js.description,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", Json_Decode$JsonCombinators.$$int),
              countryId: field.required("countryId", Json_Decode$JsonCombinators.$$int),
              stateId: field.required("stateId", Json_Decode$JsonCombinators.$$int),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              altNames: field.required("altNames", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder));
}

function toJson(active) {
  if (active === "Active") {
    return true;
  } else {
    return false;
  }
}

function toString(value) {
  if (value === "Active") {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromJs$1(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

function fromBool(active) {
  if (active) {
    return "Active";
  } else {
    return "Inactive";
  }
}

var Active = {
  toJson: toJson,
  toString: toString,
  fromJs: fromJs$1,
  fromBool: fromBool
};

function fromJs$2(js) {
  return {
          id: js.id,
          name: js.name,
          slug: js.slug,
          stateName: js.stateName,
          stateSlug: js.stateSlug,
          countryName: js.countryName,
          countrySlug: js.countrySlug,
          totalLocations: js.totalLocations
        };
}

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              stateName: field.required("stateName", Json_Decode$JsonCombinators.string),
              stateSlug: field.required("stateSlug", Json_Decode$JsonCombinators.string),
              countryName: field.required("countryName", Json_Decode$JsonCombinators.string),
              countrySlug: field.required("countrySlug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$1(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$1));
}

var Tile = {
  fromJs: fromJs$2,
  decoder: decoder$1,
  fromJson: fromJson$1
};

function fromJs$3(js) {
  return {
          cities: Belt_Array.map(js.cities, (function (city) {
                  return fromJs$2(city);
                })),
          totalCities: js.totalCities,
          totalPages: js.totalPages,
          currentPage: js.currentPage,
          offset: js.offset,
          totalLocations: js.totalLocations,
          totalStates: js.totalStates,
          totalCountries: js.totalCountries
        };
}

var decoder$2 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              cities: field.required("cities", Json_Decode$JsonCombinators.array(decoder$1)),
              totalCities: field.required("totalCities", Json_Decode$JsonCombinators.$$int),
              totalPages: field.required("totalPages", Json_Decode$JsonCombinators.$$int),
              currentPage: field.required("currentPage", Json_Decode$JsonCombinators.$$int),
              offset: field.required("offset", Json_Decode$JsonCombinators.$$int),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int),
              totalStates: field.required("totalStates", Json_Decode$JsonCombinators.$$int),
              totalCountries: field.required("totalCountries", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$2(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$2));
}

var Index = {
  fromJs: fromJs$3,
  decoder: decoder$2,
  fromJson: fromJson$2
};

var decoder$3 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              totalLocations: field.required("totalLocations", Json_Decode$JsonCombinators.$$int)
            };
    });

function fromJson$3(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$3));
}

var ListItem = {
  decoder: decoder$3,
  fromJson: fromJson$3
};

var decoder$4 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              cities: field.required("cities", Json_Decode$JsonCombinators.array(decoder$3))
            };
    });

function fromJson$4(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$4));
}

var List = {
  decoder: decoder$4,
  fromJson: fromJson$4
};

function fromJs$4(js) {
  return {
          id: js.id,
          countryId: js.countryId,
          stateId: js.stateId,
          name: js.name,
          countryName: js.countryName,
          stateName: js.stateName,
          slug: js.slug,
          countrySlug: js.countrySlug,
          stateSlug: js.stateSlug,
          active: js.active,
          metaTitle: js.metaTitle,
          metaDescription: js.metaDescription,
          altNames: js.altNames,
          description: js.description,
          locationsCount: js.locationsCount,
          locationsCountActive: js.locationsCountActive,
          createdAt: $$Date.Naive.fromString(js.createdAt),
          updatedAt: $$Date.Naive.fromString(js.updatedAt)
        };
}

var decoder$5 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              id: field.required("id", ID.decoder),
              countryId: field.required("countryId", ID.decoder),
              stateId: field.required("stateId", ID.decoder),
              name: field.required("name", Json_Decode$JsonCombinators.string),
              countryName: field.required("countryName", Json_Decode$JsonCombinators.string),
              stateName: field.required("stateName", Json_Decode$JsonCombinators.string),
              slug: field.required("slug", Json_Decode$JsonCombinators.string),
              countrySlug: field.required("countrySlug", Json_Decode$JsonCombinators.string),
              stateSlug: field.required("stateSlug", Json_Decode$JsonCombinators.string),
              active: field.required("active", Json_Decode$JsonCombinators.bool),
              metaTitle: field.required("metaTitle", Json_Decode$JsonCombinators.string),
              metaDescription: field.required("metaDescription", Json_Decode$JsonCombinators.string),
              altNames: field.required("altNames", Json_Decode$JsonCombinators.array(Json_Decode$JsonCombinators.string)),
              description: field.required("description", Json_Decode$JsonCombinators.string),
              locationsCount: field.required("locationsCount", Json_Decode$JsonCombinators.$$int),
              locationsCountActive: field.required("locationsCountActive", Json_Decode$JsonCombinators.$$int),
              createdAt: field.required("createdAt", $$Date.Naive.decoder),
              updatedAt: field.required("updatedAt", $$Date.Naive.decoder)
            };
    });

function fromJson$5(json) {
  return Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder$5));
}

var Dashboard = {
  fromJs: fromJs$4,
  decoder: decoder$5,
  fromJson: fromJson$5
};

var Edit;

export {
  fromJs ,
  decoder ,
  fromJson ,
  Active ,
  Tile ,
  Index ,
  ListItem ,
  List ,
  Dashboard ,
  Edit ,
}
/* decoder Not a pure module */
